<template>
    <div class="service">
        <Top name="联系客服" back email v-if="topShow"></Top>
        <van-row type="flex" justify="center">
            <van-col span="22">
                <div class="service_w">
                    <!-- <div class="service_w_c">
                        <p>
                            <img src="../../assets/images/service_icon1.png" alt="">
                            <span>客服电话</span>
                        </p>
                        <p>专属客服电话（{{tel}}）</p>
                    </div> -->
                    <div class="service_w_c">
                        <p>
                            <img src="../../assets/images/service_icon2.png" alt="">
                            <span>客服微信</span>
                        </p>
                        <p>微信号（{{wechat}}）</p>
                    </div>
                    <div class="service_w_c" v-if="isShow">
                        <p>
                            <img src="../../assets/images/service_icon3.png" alt="">
                            <span>客服QQ</span>
                        </p>
                        <p>QQ号（{{qq}}）</p>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Service',
    components: {
        Top,
    },
    data() {
        return {
            params: {},
            topShow: true,
            tel: '',
            wechat: '',
            qq: '',
            isShow:false
        }
    },
    created(){
        //获取路由参数
        this.params = this.$route.query;
        //有tag参数是app访问，需去掉头部
        if (this.params.tag) {
            this.topShow = false;
        }else{
            this.topShow = true;
        }
        //获取客服信息
        this.getService();
    },
    methods: {
        //获取客服信息
        getService() {
            this.$api.account.getService().then(res => {
                if (res.code == 200) {
                    if(sessionStorage.getItem('type')=='24'){
                        this.wechat = 'yueyou2688'
                        return false
                    }else{
                        this.isShow = true
                    }
                    this.tel = res.data.tel;
                    this.wechat = res.data.wechat;
                    this.qq = res.data.qq;
                }
            });
        },
    }
}
</script>
<style scoped>
.service{
    min-height: 100%;
    background-color: #ececec;
}
.service .van-row{
    padding: 1.125rem 0;
}
.service_w{
    background: #fff url('../../assets/images/service_bg.png') no-repeat bottom right;
    background-size: 100%;
    padding: 1.0625rem 1.1875rem;
    border-radius: 14px;
    font-size: .8125rem;
    line-height: 1.8em;
    text-align: left;
}
.service_w_c p img{
    width: .875rem;
    height: .875rem;
    margin-right: .3125rem;
    display: inline-block;
    vertical-align: middle;
}
.service_w_c p span{
    display: inline-block;
    vertical-align: middle;
}
.service_w_c p:first-child span{
    color: #FF4E00;
}
.service_w_c{
    margin-bottom: .625rem;
}
.service_w .service_w_c:last-child{
    margin-bottom: 0;
}
</style>